import { Container } from "@mui/material";
import React from "react";
import SP_Image from "../asset/image/banners/our projects.png";
// import Gkbhim from "../asset/image/our-project/Gkbim banners-01.png";
// import Gkbhim_one from "../asset/image/our-project/Gkbim banners-02.png";
import Gkbhim_two from "../asset/image/our-project/Gkbim banners-03.png";
import Gkbhim_three from "../asset/image/our-project/Gkbim banners-04.png";
import Gkbhim_four from "../asset/image/our-project/Gkbim banners-05.png";
import Gkbhim_five from "../asset/image/our-project/Gkbim banners-06.png";
import Gkbhim_six from "../asset/image/our-project/Gkbim banners-07.png";
import Gkbhim_seven from "../asset/image/our-project/Gkbim banners-08.png";
import "../asset/style/our_project.css";

export const OurProjectPage = () => {
  return (
    <section className="contact_section">
      <div className="sp_banner">
        <img
          src={SP_Image}
          alt="service_img"
          width=""
          height=""
          loading="lazy"
        />
        <div className="sp_content">
          <h1 className="sp_title">Our Projects</h1>
          <p className="sp_para">
            GLOBAL KING can make your next project successful.
          </p>
        </div>
      </div>
      {/* <Container maxWidth={"100%"}>
        <div className="project-banner">
          <div className="project-ban-left-sec">
            <h1 className="sp_title">Our Projects</h1>
          </div>
          <div className="project-ban-right-sec">
            <img
              src={Gkbhim}
              alt="service_img"
              width="100%"
              height="auto"
              loading="lazy"
            />
            <img
              src={Gkbhim_one}
              alt="service_img"
              width="100%"
              height="auto"
              loading="lazy"
            />
          </div>
        </div>
      </Container> */}
      <Container maxWidth={"lg"}>
        <div className="project-banner">
          <div className="main-wrapper-two">
            <div className="first-img">
              <h1>Al Zarooni – Residential Building
              </h1>
              <img
                src={Gkbhim_two}
                alt="service_img"
                width="100%"
                height="auto"
                loading="lazy"
              />
            </div>
            <div className="second-img">
              <h1>Elington – Residential Building</h1>
              <img
                src={Gkbhim_three}
                alt="service_img"
                width="100%"
                height="auto"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </Container>
      <Container maxWidth={"lg"}>
        <div className="project-banner">
          <div className="main-wrapper-two">
            <div className="first-img">
              <h1>Golf Residence – Residential Building</h1>
              <img
                src={Gkbhim_four}
                alt="service_img"
                width="100%"
                height="auto"
                loading="lazy"
              />
            </div>
            <div className="second-img">
              <h1>Food Hall - Expo</h1>
              <img
                src={Gkbhim_five}
                alt="service_img"
                width="100%"
                height="auto"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </Container>
      <Container maxWidth={"lg"}>
        <div className="project-banner">
          <div className="main-wrapper-two">
            <div className="first-img">
              <h1>Manaesho Restaurant
              </h1>
              <img
                src={Gkbhim_six}
                alt="service_img"
                width="100%"
                height="auto"
                loading="lazy"
              />
            </div>
            <div className="second-img">
              <h1>Poland Pavillion</h1>
              <img
                src={Gkbhim_seven}
                alt="service_img"
                width="100%"
                height="auto"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
