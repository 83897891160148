import { Container } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider_img_1 from "../../asset/image/staff_pic_new.png";
import slider_img_0 from "../../asset/image/gkbim-safi.png";
import slider_img_01 from "../../asset/image/gkbim-manju.png";
import Slider_img_2 from "../../asset/image/profile_image-2.png";
import slider_img_5 from "../../asset/image/Hassitha-Photo.png";
import worker_img_1 from "../../asset/image/workers/1.png"
import worker_img_2 from "../../asset/image/workers/team (2).png"
import worker_img_3 from "../../asset/image/workers/3.png"
import worker_img_4 from "../../asset/image/workers/4.png"
import LazyImage from "../loader/LazyImage";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 510,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// const bio_detials = [
//     {
//         name: 'Elangovan A',
//         img_path: Slider_img_1,
//         detial_1: 'BIM SPECIALIST',
//         detial_2: 'Chief Executive Officer',
//         detial_3: 'Dubai, UAE',
//         description: "Motivated to excel in the professional realm of mep bim, he demonstrates exceptional proficiency in 3d modeling and engineering. recognized for his steadfast dedication, he actively seeks opportunities for professional development, taking pride in consistently delivering meticulous work aligned with the nuanced requirements of mep bim processes",
//     },
//     {
//         name: 'Sekar periyasamy',
//         img_path: Slider_img_2,
//         detial_1: 'MEP SYSTEMS SPECIALIST',
//         detial_2: 'Technical Director',
//         detial_3: 'Dubai, UAE',
//         description: "Meet Sekar Periyasamy, an accomplished Technical Director specializing in MEP systems. With a robust background in HVAC, electrical, plumbing, and fire protection, Sekar seamlessly oversees complex projects, excelling in collaboration with clients and consultants. His adept communication skills bridge technical intricacies and client expectations. Leading with precision, Sekar ensures timely project delivery and budget adherence, fostering innovation and excellence within his teams. His commitment to excellence makes him a standout figure in the MEP industry.",
//     },
// {
//     name: 'Elangovan A',
//     img_path: Slider_img_1,
//     detial_1: 'BIM SPECIALIST',
//     detial_2: 'Chief Executive Officer',
//     detial_3: 'Dubai, UAE',
// },
// {
//     name: 'Elangovan A',
//     img_path: Slider_img_1,
//     detial_1: 'BIM SPECIALIST',
//     detial_2: 'Chief Executive Officer',
//     detial_3: 'Orlando, Florida',
// }
// ]
function AboutDrag() {
  const [isDown, setIsDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeftState, setScrollLeftState] = useState(null);
  const [mouseMoved, setStateMouseMoved] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleOpen1 = () => setOpen1(true);
  const handleOpen2 = () => setOpen2(true);
  const handleOpen3 = () => setOpen3(true);
  const handleOpen4 = () => setOpen4(true);
  const handleClose = () => setOpen(false);
  const handleClose1 = () => setOpen1(false);
  const handleClose2 = () => setOpen2(false);
  const handleClose3 = () => setOpen3(false);
  const handleClose4 = () => setOpen4(false);

  const itemsContainer = useRef();

  // functions
  function handleMouseDown(e) {
    setIsDown(true);

    if (e.pageX === undefined) {
      setStartX(e.touches[0].pageX - itemsContainer.current.offsetLeft);
    } else {
      setStartX(e.pageX - itemsContainer.current.offsetLeft);
    }

    setScrollLeftState(itemsContainer.current.scrollLeft);
    setStateMouseMoved(0);
  }

  function handleMouseMove(e) {
    if (!isDown) {
      return;
    }

    const currentMousePositionInsideContainer =
      e.pageX === undefined
        ? e.touches[0].pageX - itemsContainer.current.offsetLeft
        : e.pageX - itemsContainer.current.offsetLeft;

    setStateMouseMoved(currentMousePositionInsideContainer - startX);
  }

  useEffect(() => {
    itemsContainer.current.scrollLeft = scrollLeftState - mouseMoved;
  }, [scrollLeftState, mouseMoved]);
  return (
    <section className="ad__container">
      <Container maxWidth={"lg"}>
        <div className="ad__heading">
          <h1 className="ad_title">Our Leaders</h1>
          <p className="ad_para">
            Globalking Engineering Solutions is a transparent engineering
            company, having earned our JUST label in 2016 and improved our
            rating in the 2018 renewal. As a closely held corporation, our
            shareholders are employees who have invested in the future of our
            firm and are committed to our mission.
          </p>
          <Link className="link_button move_arrow_contain">
            Meet Our Leadership<span className="moving_arrow">→</span>
          </Link>
        </div>
      </Container>
      <div className="ad__MainContainer">
        <div
          className={`ad__ItemsContainer ${isDown ? "ad__activeClass" : ""}`}
          ref={itemsContainer}
          // MOUSE EVENTS
          onMouseDown={(e) => handleMouseDown(e)}
          onMouseUp={() => setIsDown(false)}
          onMouseLeave={() => setIsDown(false)}
          onMouseMove={(e) => handleMouseMove(e)}
          // TOUCH EVENTS
          onTouchStart={(e) => handleMouseDown(e)}
          onTouchEnd={() => setIsDown(false)}
          onTouchCancel={() => setIsDown(false)}
          onTouchMove={(e) => handleMouseMove(e)}
        >
          {/* <div className="ad__item ad_item_m">
            <div className="ad__item_contain">
              <div className="ad__img">
                <LazyImage actualImageSrc={slider_img_0} Alt="Slider_img_1" />
              </div>
              <div className="ad__content">
                <h1 className="ad_name">Sheron Shafi</h1>
                <p className="ad_detail_1"></p>
                <p className="ad_detail_2">General Manager</p>
                <div className="ad_detail_3">
                  <p>Dubai, UAE</p>
                  <Button
                    onClick={handleOpen1}
                    sx={{ fontWeight: "600", padding: 0, lineHeight: 0 }}
                  >
                    Read More
                  </Button>
                  <Modal
                    open={open1}
                    onClose={handleClose1}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        Sheron Shafi
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Sheron Shafi is an accomplished Building Services
                        Engineer with over 20 years of experience in the
                        industry. He began his career at WSP Middle East,
                        gaining foundational expertise in mechanical engineering
                        and project management.
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Throughout his career, Sheron expanded his skill set
                        through roles at leading consultancy firms, including
                        the National Engineering Bureau and Chapman BDSP, where
                        he contributed to notable engineering projects.
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Driven by a passion for innovation, Sheron has grown
                        into a distinguished provider of electromechanical
                        engineering solutions. Under his leadership, he was
                        recognized By many High-Profile Firms, highlighting its
                        commitment to excellence and quality in service
                        delivery.
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        In response to the industry's evolving needs, Sheron
                        also established GK BIM Solutions in india, a firm
                        dedicated to providing advanced BIM services that
                        enhance project accuracy and collaboration. Today,
                        Sheron’s dual leadership roles at Datahub Technologies
                        (UAE Counterpart of GK BIM) and GK BIM Solutions reflect
                        his dedication to pushing boundaries in engineering and
                        adopting digital solutions to meet industry demands
                        effectively.
                      </Typography>
                    </Box>
                  </Modal>
                </div>
              </div>
            </div>
          </div> */}
          <div className="ad__item ad_item_m">
            <div className="ad__item_contain">
              <div className="ad__img">
                <LazyImage actualImageSrc={Slider_img_2} Alt="Slider_img_2" />
              </div>
              <div className="ad__content">
                <h1 className="ad_name">Sekar Periyasamy</h1>
                <p className="ad_detail_1"></p>
                <p className="ad_detail_2">Technical Director</p>
                <div className="ad_detail_3">
                  <p>India, Tirchy</p>
                  <Button
                    onClick={handleOpen}
                    sx={{ fontWeight: "600", padding: 0, lineHeight: 0 }}
                  >
                    Read More
                  </Button>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        Sekar periyasamy
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Meet Sekar Periyasamy, an accomplished Technical
                        Director specializing in MEP systems. With a robust
                        background in HVAC, electrical, plumbing, and fire
                        protection, Sekar seamlessly oversees complex projects,
                        excelling in collaboration with clients and consultants.
                        His adept communication skills bridge technical
                        intricacies and client expectations. Leading with
                        precision, Sekar ensures timely project delivery and
                        budget adherence, fostering innovation and excellence
                        within his teams. His commitment to excellence makes him
                        a standout figure in the MEP industry.
                      </Typography>
                    </Box>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
          <div className="ad__item ad_item_m">
            <div className="ad__item_contain">
              <div className="ad__img">
                <LazyImage actualImageSrc={slider_img_5} Alt="Slider_img_5" />
              </div>
              <div className="ad__content">
                <h1 className="ad_name">Hassitha</h1>
                <p className="ad_detail_1"></p>
                <p className="ad_detail_2">Head of Digital Project Delivery</p>
                <div className="ad_detail_3">
                  <p>India, Tirchy</p>
                  <Button
                    onClick={handleOpen4}
                    sx={{ fontWeight: "600", padding: 0, lineHeight: 0 }}
                  >
                    Read More
                  </Button>
                  <Modal
                    open={open4}
                    onClose={handleClose4}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        Hassitha
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      She started her career as a Steel Detailer with several years of hands-on experience in complex Pre-Engineered Buildings (PEB), where she built a solid foundation in structural design, fabrication, and project execution. Her attention to detail and deep understanding of construction processes allowed her to efficiently handle intricate tasks and ensure precision in every project.

                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      As her experience grew, so did her interest in project management and large-scale project execution. She became particularly passionate about leveraging digital solutions, such as Building Information Modeling (BIM), to transform project workflows and enhance construction efficiency.

                      </Typography>
      
                      <Typography>
                      Through continuous hard work, dedication, and technical expertise, she was offered the opportunity to step into the role of Head of Digital Project Delivery at GK BIM Digital Solutions. In this leadership position, she now applies her background technical experience  to guide digital project teams, manage the complete delivery of BIM-based solutions, and implement advanced technologies to optimize project management and execution within the building services industry.
                      </Typography>
                    </Box>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`ad__ItemsContainer ${isDown ? "ad__activeClass" : ""}`}
          ref={itemsContainer}
          // MOUSE EVENTS
          onMouseDown={(e) => handleMouseDown(e)}
          onMouseUp={() => setIsDown(false)}
          onMouseLeave={() => setIsDown(false)}
          onMouseMove={(e) => handleMouseMove(e)}
          // TOUCH EVENTS
          onTouchStart={(e) => handleMouseDown(e)}
          onTouchEnd={() => setIsDown(false)}
          onTouchCancel={() => setIsDown(false)}
          onTouchMove={(e) => handleMouseMove(e)}
        >
          <div className="ad__item ad_item_m">
            <div className="ad__item_contain">
              <div className="ad__img">
                <LazyImage actualImageSrc={slider_img_01} Alt="Slider_img_1" />
              </div>
              <div className="ad__content">
                <h1 className="ad_name">Manjunatha</h1>
                <p className="ad_detail_1">BIM Coordinator</p>
                <p className="ad_detail_2">Manager</p>
                <div className="ad_detail_3">
                  <p>Dubai, UAE</p>
                  <Button
                    onClick={handleOpen3}
                    sx={{ fontWeight: "600", padding: 0, lineHeight: 0 }}
                  >
                    Read More
                  </Button>
                  <Modal
                    open={open3}
                    onClose={handleClose3}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        Manjunatha
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Offering 17 years of experience in the Building Services
                        field, demonstrated proficiency in a BIM Coordinator
                        role, with 10 years of expertise in the Middle East.
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Adept at managing diverse projects, including high-rise
                        buildings, malls, hotels, airports, museums, educational
                        buildings, mixed-use commercial and residential
                        structures, and prestigious palaces. In the capacity of
                        a BIM Coordinator, champions and supports the BIM
                        delivery of Middle East Building Services projects.
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Integration of BIM into successful project delivery
                        through thorough coordination and promotion of BIM
                        protocols is a key focus. Additionally, involved in MEP
                        services coordination, providing training to the CAD/BIM
                        team to enhance overall production efficiency.
                        Participation in the review and commentary on shop
                        drawings in alignment with design drawings and site
                        conditions is integral. Proficient in the latest version
                        of Revit MEP, possesses in-depth knowledge of authority
                        requirements and standards such as DCD, ADM, DM, and
                        DEWA.
                      </Typography>
                    </Box>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
          <div className="ad__item ad_item_m">
            <div className="ad__item_contain">
              <div className="ad__img">
                <LazyImage
                  actualImageSrc={Slider_img_1}
                  Alt="Sekar periyasamy"
                />
              </div>
              <div className="ad__content">
                <h1 className="ad_name">Mohammed Abubakkar Siddiq</h1>
                <p className="ad_detail_1">BIM Lead</p>
                <p className="ad_detail_2">BIM Coordinator</p>
                <div className="ad_detail_3">
                  <p>India, Tirchy</p>
                  <Button
                    onClick={handleOpen2}
                    sx={{ fontWeight: "600", padding: 0, lineHeight: 0 }}
                  >
                    Read More
                  </Button>
                  <Modal
                    open={open2}
                    onClose={handleClose2}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={style}>
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        Mohammed Abubakkar Siddiq
                      </Typography>
                      <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Over 10 years of extensive experience in BIM Modelling,
                        Coordination, Project Planning, Project Execution, Team
                        Management, and Engineering. Advanced knowledge in
                        Autodesk tools and standards, with hands-on experience
                        in Revit modelling (Architecture, Structure, HVAC, Fire
                        Protection, Plumbing, Electrical), Family creation,
                        Navisworks coordination, clash detection, and
                        proficiency in AutoCAD, Revizto, and Aconex. Proficient
                        in creating Common Data Environments (CDE) and working
                        with BIM 360. Strong understanding of BIM Execution
                        Plans (BEP) and ISO 19560 standards, applied in multiple
                        GCC projects. Proven ability to lead and manage BIM
                        teams effectively.
                      </Typography>
                      <Typography>
                        Integration of BIM into successful project delivery
                        through thorough coordination and promotion of BIM
                        protocols is a key focus. Additionally, involved in MEP
                        services coordination, providing training to the CAD/BIM
                        team to enhance overall production efficiency.
                        Participation in the review and commentary on shop
                        drawings in alignment with design drawings and site
                        conditions is integral. Proficient in the latest version
                        of Revit MEP, possesses in-depth knowledge of authority
                        requirements and standards such as DCD, ADM, DM, and
                        DEWA.
                      </Typography>
                    </Box>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="ad__container">
              <h1 className="ad_title">Our Team</h1>
              <div className="image-container">
                  <div className="img-one">
                      <LazyImage actualImageSrc={worker_img_1} Alt="worker_img_1" />
                  </div>
                  <div className="img-one">
                      <LazyImage actualImageSrc={worker_img_2} Alt="worker_img_2" />
                  </div>
              </div>
              <div className="breaak"></div>
              <div className="image-container">
                  <div className="img-one">
                      <LazyImage actualImageSrc={worker_img_3} Alt="worker_img_3" />
                  </div>
                  <div className="img-one">
                      <LazyImage actualImageSrc={worker_img_4} Alt="worker_img_4" />
                  </div>
              </div>
        </section>
        
      </div>
    </section>
  );
}

export default AboutDrag;
